import React from "react";
import '../assets/css/templates/landing-template.css';
import { Helmet } from "react-helmet";
import { ContextWithPrivacyPolicyItemProps } from "../types/privacyPolicyItem";
import ReactGA from 'react-ga';

const PrivacyPolicyTemplate: React.FC<ContextWithPrivacyPolicyItemProps> = ( { pageContext: { id, name} }) => {
  const data = require(`../data/privacyPolicyDataContent/${id}.html`).default;
     React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/privacy/${id}`);
   }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div className="landing-overflow"></div>
      <div className="landing">
        <div className="container">
          <div dangerouslySetInnerHTML={{__html: data}}></div>
        </div>
    </div>  
    </React.Fragment>
  );
}

export default PrivacyPolicyTemplate;